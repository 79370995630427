<ng-container *ngIf="loaded; else loading">
  <div [hidden]="!data || !fieldsLoaded">
    <form #form="ngForm" class="redesign">
      <ng-container *ngIf="this.cicoService.activeStep.currentStep === 1">
        <div class="flex-2col">
          <straiv-reservation-card
            is-upgrade-card="false"
            size="medium"
            [imageUrl]="reservationImageUrl"
            [heading]="data.incident.reservation?.unit?.name"
            [description]="data.incident.reservation?.unit?.description"
            [viewDetailsButtonText]="'booking_data.transportation.viewDetails' | translate"
            selectable="false"
            [extrasHeading]="'booking_data.transportation.extras' | translate"
            [extras]="extras"
            [arrivalDate]="arrival"
            [departureDate]="departure"
            [checkInTime]="checkInTime"
            [checkOutTime]="checkOutTime"
          ></straiv-reservation-card>
        </div>

        <div class="flex-2col" *ngIf="fields.time_of_arrival || fields.time_of_departure">
          <straiv-input *ngIf="fields.time_of_arrival" ngDefaultControl name="time_of_arrival" type="time" [labelText]="fields.time_of_arrival.name"
            [helperText]="fields.time_of_arrival.description" [placeholder]="fields.time_of_arrival.name" [required]="fields.time_of_arrival.required" 
            [errorMessage]="'validation.required' | translate" [(ngModel)]="mappedFormFieldsValues.time_of_arrival.value">
          </straiv-input>

          <straiv-input *ngIf="fields.time_of_departure" ngDefaultControl name="time_of_departure" type="time" [labelText]="fields.time_of_departure.name"
            [helperText]="fields.time_of_departure.description" [placeholder]="fields.time_of_departure.name" [required]="fields.time_of_departure.required" 
            [errorMessage]="'validation.required' | translate" [(ngModel)]="mappedFormFieldsValues.time_of_departure.value">
          </straiv-input>
        </div>

        <div class="flex-2col" *ngIf="fields.coming_from || fields.destination">
          <straiv-input *ngIf="fields.coming_from" ngDefaultControl name="coming_from" [labelText]="fields.coming_from.name" 
          [helperText]="fields.coming_from.description" [placeholder]="fields.coming_from.name" [required]="fields.coming_from.required" 
          [errorMessage]="'validation.required' | translate" [(ngModel)]="mappedFormFieldsValues.coming_from.value">
          </straiv-input>

          <straiv-input *ngIf="fields.destination" ngDefaultControl name="destination" [labelText]="fields.destination.name" 
          [helperText]="fields.destination.description" [placeholder]="fields.destination.name" [required]="fields.destination.required" 
          [errorMessage]="'validation.required' | translate" [(ngModel)]="mappedFormFieldsValues.destination.value">
          </straiv-input>
        </div>

        <div class="flex-2col" *ngIf="fields.border_crossing || fields.border_crossing_date">
          <straiv-input *ngIf="fields.border_crossing" ngDefaultControl name="border_crossing" [labelText]="fields.border_crossing.name" 
            [helperText]="fields.border_crossing.description" [placeholder]="fields.border_crossing.name" [required]="fields.border_crossing.required" 
            [errorMessage]="'validation.required' | translate" [(ngModel)]="mappedFormFieldsValues.border_crossing.value">
          </straiv-input>

          <straiv-datepicker *ngIf="fields.border_crossing_date" ngDefaultControl name="border_crossing_date" [labelText]="fields.border_crossing_date.name" 
            [required]="fields.border_crossing_date.required" [errorMessage]="datePickerValidationMessages | translate" 
            [helperText]="fields.border_crossing_date.description" [validation]="'past'"
            [(ngModel)]="mappedFormFieldsValues.border_crossing_date.value" [placeholder]="fields.border_crossing_date.name" (dateBlur)="setDatePickerValidationMessage($event)">
          </straiv-datepicker>
        </div>
      </ng-container>
      <ng-container *ngIf="data.module.fields && cicoService.activeStep.currentStep > (rule ? rule.products?.length + 1 : 1)"> 
        <app-form-fields [form]="data.incident" [module]="data.module" [redesign]="true" [submited]="submited"></app-form-fields>
      </ng-container>
    </form>
  </div>
  <!-- Cross and upsell products -->
  <ng-container *ngIf="rule && cicoService.activeStep.currentStep > 1 && cicoService.activeStep.currentStep <= rule.products.length + 1">
    <div *ngFor="let product of rule.products; let i = index">
      <div class="container animation-out">
        <app-product [product]="product.product" [index]="i" [totalGuestCount]="totalGuestCount" [nightsCount]="nightsCount" [reservationCusInfo]="reservationCusInfo" [rule]="rule"></app-product>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loading>
  <app-loader [small]="true" [included]="true" [headline]="'misc.loading'"></app-loader>
</ng-template>
